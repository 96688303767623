import { React, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux/';
import classNames from 'classnames';
import * as routes from 'constants/routes';

import Table from 'components/common/table/Table';
import Toast from 'components/common/toast/Toast';
import Loader from 'components/common/loader/Loader';
import EmptyPage from 'components/common/emptypage/EmptyPage';
import ClientDropDown from 'components/common/dropdown/clientDropDown';
import ContinueButton from 'components/common/continueButton/continueButton';
import { Notify } from 'components/common/notify/Notify';
import { getJobStatus } from 'services/jobs';
import { updateProject } from 'services/projectAnalysis';

import { columnConfig } from './columnConfig';

import { getDataHintValidationResult, runDataHints } from 'services/dataHints';
import {
  ERROR,
  ANALYSING,
  JOB_FAILED,
  JOB_SUBMITTED,
  JOB_COMPLETED,
  NO_JOB_STARTED,
  JOB_DATA_HINT_VALIDATION,
} from 'constants/jobResponses';

import {
  DEFAULT_ERROR_MESSAGE,
  DATAHINT_EXECUTION_FAILURE_MESSAGE,
} from 'constants/errorMessages';
import Icon from 'components/common/icons/Icons';
import { RULES } from 'constants/projectStatuses';
import { formatDateWithTime } from 'utils/common/formatter';
import { updateSelectedProject } from 'actions/clientAction';

const DataHintsRuleLibrary = () => {
  const [toast, setToast] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataHints, setDataHints] = useState([]);
  const [jobStatus, setJobStatus] = useState(null);
  const [navigateTo, setNavigateTo] = useState('');

  const [isRunningDataHints, setIsRunningDataHints] = useState(false);

  const dispatch = useDispatch();

  const { clientList, selectedClientId, selectedProject } = useSelector(
    (state) => state
  );

  const selectedClient = clientList.find(
    (client) => parseInt(client.client_id) === parseInt(selectedClientId)
  );

  const fetchDataHints = async () => {
    if (selectedClientId) {
      setIsLoading(true);
      try {
        const dataHints = await getDataHintValidationResult({
          project_id: selectedProject.projectId,
        });
        setDataHints(dataHints);
      } catch (error) {
        setToast({
          message: error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE,
          isError: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getDataHintLibraryData = async () => {
    if (selectedProject.projectId) {
      try {
        const response = await getJobStatus(selectedProject.projectId);
        const statusData = response[JOB_DATA_HINT_VALIDATION.toLowerCase()];

        if (!statusData) {
          setJobStatus(NO_JOB_STARTED);

          return;
        }

        setJobStatus(statusData.job_status);

        if (statusData.job_status === JOB_COMPLETED) {
          fetchDataHints();
        }
      } catch (error) {
        const errorMessage =
          error.response.data.detail || DATAHINT_EXECUTION_FAILURE_MESSAGE;

        Notify.error({
          title: errorMessage,
        });

        setIsLoading(false);
      }
    }
  };

  const handleNextStep = async () => {
    if (selectedProject.projectId) {
      try {
        const data = await updateProject(
          selectedClientId,
          selectedProject.projectId,
          {
            status: RULES.toUpperCase(),
          }
        );

        dispatch(
          updateSelectedProject({
            ...selectedProject,
            status: RULES.toUpperCase(),
            lastUpdateDate: formatDateWithTime(data.update_date),
          })
        );

        setNavigateTo(routes.RULE_LIBRARY);
      } catch (error) {
        Notify.error({
          title: DEFAULT_ERROR_MESSAGE,
        });
      }
    }
  };

  useEffect(() => {
    getDataHintLibraryData();
  }, []);

  const handleBadgeClick = () => {
    window.open(routes.JOBS, '_blank');
  };

  const getProjectDisplayStatus = (projectStatus, jobStatus) => {
    if (jobStatus === JOB_SUBMITTED) {
      return ANALYSING;
    }

    if (jobStatus === JOB_FAILED) {
      return ERROR;
    }

    if (!projectStatus) {
      return '';
    }

    return projectStatus;
  };

  const clientDisplayName = selectedClient
    ? selectedClient.display_name
    : 'Select the Client';

  const resetError = () => {
    setToast({});
  };

  const badgeStatus = getProjectDisplayStatus(
    selectedProject.status,
    jobStatus
  );

  const badgeClass = classNames({
    badge: true,
    error: badgeStatus === ERROR,
    highlight: badgeStatus === ANALYSING,
  });

  const handleRunDataHints = async () => {
    try {
      setIsRunningDataHints(true);
      const updatePayload = {
        client_id: selectedClientId,
        project_id: selectedProject.projectId,
      };

      await runDataHints(updatePayload);

      setJobStatus(JOB_SUBMITTED);
    } catch (error) {
      Notify.error({
        title: DATAHINT_EXECUTION_FAILURE_MESSAGE,
      });
    } finally {
      setIsRunningDataHints(false);
    }
  };

  return navigateTo ? (
    <Navigate to={navigateTo} replace={true} />
  ) : (
    <>
      <div className="bg-grey--5 pt-5x sticky d-flex flex-direction-column">
        <div className="profile mb-3x ml-auto">
          <ClientDropDown />
        </div>
        <div className="d-flex justify-content-between align-items-center mb-1x">
          <h1>
            Data Hints Library{' '}
            {selectedClient && <span>({clientDisplayName})</span>}
          </h1>
          {badgeStatus && (
            <div
              role={'button'}
              className={`${badgeClass} badge-lg ml-5x mt-3x`}
              onClick={handleBadgeClick}
            >
              {badgeStatus}
            </div>
          )}
          <button
            className="btn-has-icon ml-5x mr-auto mt-3x"
            onClick={() => {
              window.location.reload(false);
            }}
          >
            <Icon icon="refresh" size={12} color="#546071" className="mr-2x" />
            Refresh
          </button>
          <div className="color-grey--60 mt-4x">
            Last Updated : {selectedProject.lastUpdateDate}
          </div>
        </div>
      </div>
      {!selectedClientId ? (
        <EmptyPage pageName="datahints" selectionParameters="client" />
      ) : (
        <>
          {isLoading && <Loader isFullScreen={true} />}
          <div className="table table-25vh has-box-shadow">
            <Table
              className="has-box-shadow"
              data={dataHints}
              columnConfig={columnConfig}
            />
          </div>
          <div className="d-flex justify-content-end mt-3x mb-6x">
            <button className="btn btn-primary" onClick={handleRunDataHints}>
              Run Data Hints
              {isRunningDataHints && <span className="spinner" />}
            </button>
          </div>
          <div className="mt-auto">
            <ContinueButton onClick={handleNextStep} />
          </div>
        </>
      )}
      {toast.message ? (
        <Toast
          hasError={toast.isError}
          title={toast.message}
          handleClose={resetError}
        />
      ) : null}
    </>
  );
};

export default DataHintsRuleLibrary;
