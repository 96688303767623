import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import SelectBox from 'components/common/select/Select';
import brandLogoLg from 'assets/images/brand-logo-lg.svg';

import { getAllClients } from 'services/client';
import { updateClientID, updateClientList } from 'actions/clientAction';

import { INSURANCE_MAPPING } from 'constants/routes';

const mapStateToProps = (state) => {
  const { selectedClientId } = state;

  return { selectedClientId };
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      isSubmitted: false,
      isFetchingClientList: false,
      clientOptionsList: [],
      errorMessage: '',
    };
  }

  handleSelect = (selectedOption) => {
    this.setState({
      selectedClient: selectedOption,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.updateClientID(this.state.selectedClient.value);
    this.setState({
      isSubmitted: true,
    });
  };

  async componentDidMount() {
    try {
      this.setState({
        isFetchingClientList: true,
      });

      const data = await getAllClients();

      if (data) {
        const clientOptionsList = data.map((client) => {
          return {
            value: client.client_id,
            label: client.display_name,
          };
        });

        this.props.updateClientList(data);
        const selectedClient = clientOptionsList.find(
          (client) => client.value === this.props.selectedClientId
        );

        this.setState({
          isFetchingClientList: false,
          selectedClient,
          clientOptionsList,
        });
      }
    } catch (error) {
      this.setState({
        isFetchingClientList: false,
        errorMessage: 'Unable to fetch client list',
      });
    }
  }

  render() {
    const {
      isFetchingClientList,
      isSubmitted,
      clientOptionsList,
      selectedClient,
      errorMessage,
    } = this.state;

    const placeHolderMessage = isFetchingClientList
      ? 'Loading client list'
      : errorMessage
      ? errorMessage
      : 'Select the client';

    return (
      <>
        {isSubmitted ? (
          <Navigate to={INSURANCE_MAPPING} replace={true} />
        ) : (
          <>
            <div className="px-5x pb-5x">
              <div className="dashboard text-center">
                <h2 className="mb-4x color-primary--base">
                  Welcome to the internal application of
                </h2>
                <img className="mb-11x" src={brandLogoLg} alt="" />

                <p className="text-lg color-primary--base mb-2x ">
                  Select client
                </p>

                <form onSubmit={this.handleSubmit}>
                  <SelectBox
                    options={clientOptionsList}
                    onChange={this.handleSelect}
                    value={selectedClient}
                    placeholder={placeHolderMessage}
                  />

                  <input
                    className="btn btn-primary w-100 mt-4x"
                    type="submit"
                    value="Submit"
                    disabled={!selectedClient}
                  />
                </form>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

Dashboard.propTypes = {
  updateClientID: PropTypes.func,
  updateClientList: PropTypes.func,
  selectedClientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, { updateClientID, updateClientList })(
  Dashboard
);
