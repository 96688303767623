import config from '../config';
import http from 'utils/http';

export async function getDataHintsCategory(params = {}) {
  const url = config.endpoints.ruleCategoriesV1;
  const { data } = await http.get(url, { params });

  return data.data;
}

export async function addDataHints(body) {
  const url = config.endpoints.dataHints;
  const { data } = await http.post(url, { ...body });

  return data.data;
}

export async function getDataHints(params = {}) {
  const url = config.endpoints.dataHints;
  const { data } = await http.get(url, { params });

  return data.data;
}

export async function getDataHintValidationResult(params = {}) {
  const url = config.endpoints.dataHintsValidationResult;
  const { data } = await http.get(url, { params });

  return data.data;
}

export async function updateDataHint(id, payload) {
  const url = `${config.endpoints.dataHints}/${id}`;
  const { data } = await http.put(url, payload);

  return data.data;
}

export async function getDataHintById(id) {
  const url = `${config.endpoints.dataHints}/${id}`;
  const { data } = await http.get(url);

  return data.data;
}

export async function runDataHints(payload) {
  const url = config.endpoints.executeDataHints;

  const { data } = await http.put(url, payload);

  return data.data;
}
