import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loader from '../loader/Loader';

const Card = (props) => {
  const {
    header,
    children,
    className,
    headerClassName,
    contentClassName,
    loading,
  } = props;

  return (
    <div className={classNames('card', className)}>
      <header className={classNames('card__header', headerClassName)}>
        {header}
      </header>

      {loading ? (
        <div className="d-flex justify-content-center p-4x">
          <Loader />
        </div>
      ) : (
        <section className={classNames('card__section', contentClassName)}>
          {children}
        </section>
      )}
    </div>
  );
};

Card.propTypes = {
  /** Indicates if the card content is in a loading state */
  loading: PropTypes.bool,

  /** Additional CSS class(es) for the main card container */
  className: PropTypes.string,

  /** Additional CSS class(es) for the card header */
  headerClassName: PropTypes.string,

  /** Additional CSS class(es) for the card content section */
  contentClassName: PropTypes.string,

  /** Content to be rendered inside the card */
  children: PropTypes.node.isRequired,

  /** Content to be rendered in the card's header */
  header: PropTypes.node.isRequired,
};

export default Card;
