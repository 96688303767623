export const colors = {
  grey: {
    60: '#808285',
    40: '#a8a9ad',
    iconFill: '#dadada',
  },
  primary: {
    base: '#01263C',
    80: '#2B4154',
    15: '#b9d4ec',
  },
  red: {
    tooltipRed: '#E76161',
  },
  green: {
    tooltipGreen: '#83C447',
  },
};
